<script setup lang="ts">
import { FetchError } from 'ofetch'

useHead({
  title: 'Login',
})
const toast = useToast()
const email = ref("")
const password = ref("")
const loading = ref(false)
async function handlePasswordLogin() {
  loading.value = true
  if (!email.value || !password.value) {
    toast.add({ severity: 'error', summary: 'Info', detail: "Please enter email and password", life: 3000 })
    return
  }

  const formData = new FormData()
  formData.append("email", email.value)
  formData.append("password", password.value)
  try {
    const response = await $fetch("/api/auth/password/signin", {
      method: "POST",
      body: formData,

    })
    navigateTo("/")
  }
  catch (error: any) {
    if (error instanceof FetchError) {
      toast.add({ severity: 'error', summary: 'Info', detail: error.data.message, life: 3000 })
    }
  }
  loading.value = false
}
</script>

<template>
  <div class="flex flex-col justify-center items-center p-2 min-h-screen">
    <Toast />
    <Card class="w-70">
      <template #title>Take10 Media CMS</template>
      <template #content>
        <div class="flex flex-col gap-4">
          <div class="field">
            <InputText class="w-full" v-model="email" name="email" placeholder="Email" required />
          </div>
          <div class="field">
            <Password v-model="password" toggleMask name="password" placeholder="Password" required :feedback="false"
              promptLabel="Enter your password" />
          </div>
          <div class="field">
            <Button label="Login" @click="handlePasswordLogin" :loading="loading" />
          </div>
          <div class="field text-center">
            <small>
              <NuxtLink to="/forgotpassword" class="underline">Forgot your password?</NuxtLink>
            </small>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-center">
          <small>
            <NuxtLink to="/register" class="underline">No account yet?</NuxtLink>
          </small>
        </div>
      </template>
    </Card>
  </div>
</template>
